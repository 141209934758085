<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        <strong>Prénom:</strong> {{currentUser.username}}
      </h3>
    </header>
    <p>
      <strong>Token:</strong>
      {{accessToken.substring(0, 20)}} ... {{accessToken.substr(accessToken.length - 20)}}
    </p>
    <p>
      <strong>Id:</strong>
      {{currentUser.id}}
    </p>
    <!-- <p>
      <strong>Email:</strong>
      {{currentUser.email}}
    </p> -->
    <strong>Authorities:</strong>
    <ul>
      <li v-for="role in currentUser.roles" :key="role">{{role}}</li>
    </ul>
  </div>
</template>

<script>
import UserService from "../services/user.service";
export default {
  name: 'Profile',
  data() {
    return {
      currentUser: "",
      accessToken : JSON.parse(localStorage.getItem('userToken'))
    };
  },
 
  mounted() {
 
      UserService.getCurrentUserInfos().then(
        (response) => {
          this.currentUser = response.data["hydra:member"][1];
          console.log('response.data', response.data["hydra:member"][1])
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
 
  }
};
</script>